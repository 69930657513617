<template>
  <div class="formdata" style="padding: 20px">
    <el-row>
      <el-col :span="10">
        <div class="title">
          <div class="titletree">科室管理1</div>
          <i
            v-if="$checkPermission('ADDDEPT', $route)"
            class="el-icon-plus iconColor"
            @click="addMenuTree(null, null)"
          />
        </div>
        <el-tree
          :props="props"
          :data="treeList"
          node-key="id"
          :default-expanded-keys="deptTreeIdList"
        >
          <div slot-scope="{ node, data }" class="custom-tree-node">
            <div class="labelName" @click="queryDetail(node, data)">
              {{ node.label }}
            </div>
            <div>
              <template v-if="isgroup">
                <i
                  v-if="$checkPermission('ADDDEPT', $route)"
                  class="el-icon-plus iconColor"
                  @click="addMenuTree(node, data)"
                />
              </template>
              <i
                v-if="$checkPermission('EDITDEPT', $route)"
                class="el-icon-edit iconColor"
                @click="editTree(data)"
              />
              <i
                v-if="
                  data.pid !== null && $checkPermission('REMOVEDEPT', $route)
                "
                class="el-icon-delete iconColor"
                @click="() => removeTree(node, data)"
              />
            </div>
          </div>
        </el-tree>
      </el-col>
      <el-col :span="12">
        <div class="orgInfo">
          <template v-if="dialogVisible">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              label-width="120px"
            >
              <h3>{{ titleType }}</h3>
              <el-form-item
                label="科室编码："
                prop="deptCode"
                style="width: 80%"
              >
                <el-input
                  v-model="form.deptCode"
                  v-check-permission
                  placeholder="请输入科室编码"
                  :disabled="isDetail || isEditor"
                  maxlength="50"
                />
              </el-form-item>
              <el-form-item
                label="科室名称："
                prop="deptName"
                style="width: 80%"
              >
                <el-input
                  v-model="form.deptName"
                  v-check-permission
                  placeholder="请输入科室名称"
                  :disabled="isDetail"
                  maxlength="50"
                />
              </el-form-item>
              <el-form-item label="科室图标：" prop="logo" style="width: 80%">
                <el-upload
                  :action="url"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :disabled="isDetail"
                >
                  <img v-if="form.logo" :src="form.logo" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item v-if="!isDetail">
                <el-button type="primary" @click="saveData()"> 保存 </el-button>
                <el-button v-if="isEditor" @click="resetData"> 取消 </el-button>
              </el-form-item>
            </el-form>
          </template>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { codeRule } from '@/utils/verificationRule.js';
export default {
  name: 'AccountManageList',
  data() {
    return {
      url: `${
        window.SITE_CONFIG['apiURL']
      }/sys/oss/upload?token=${sessionStorage.getItem('token')}`,
      dialogVisible: false, // 新增、编辑科室弹框显示
      deptTreeIdList: [], // 默认展开科室树
      isEditor: false, // 科室编码是否可以修改
      titleType: '新增科室',
      isDetail: false, // 控制输入框和按钮是否可用
      deptInfo: {}, // 新增，编辑点击的节点信息
      treeList: [], // 机构树
      isgroup: true, // 默认是分组，显示
      form: {
        deptCode: '',
        deptName: '',
        logo: '',
      },
      rules: {
        deptCode: [{ required: true, validator: codeRule, trigger: 'blur' }],
        deptName: [
          {
            required: true,
            message: '请输入科室名称',
            trigger: 'blur',
          },
        ],
      },
      props: {
        label: 'deptName',
        children: 'children',
      },
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getDepartmentList();
  },
  methods: {
    ...mapActions('basicDictionary', ['deptEdit', 'deptQueryList']),
    handleAvatarSuccess(res, file) {
      this.form.logo = res.data.src;
    },
    // 编辑机构
    // 新增编辑、详情公共方法
    publicMethod(data) {
      this.isDetail = false;
      this.dialogVisible = true;
      this.deptInfo = data;
    },
    editTree(data) {
      // this.$refs['form'].resetFields();
      this.isEditor = true;
      this.titleType = '编辑科室';
      this.publicMethod(data);
      this.form = {
        deptCode: data.deptCode,
        deptName: data.deptName,
        logo: data.logo,
      };
    },
    // 新增科室树
    addMenuTree(node, data) {
      (this.isEditor = false), (this.titleType = '新增科室');
      if (!data) {
        this.dialogVisible = true;
        this.isDetail = false;
      } else {
        this.publicMethod(data);
      }
      this.form = {
        deptCode: '',
        deptName: '',
        logo: '',
      };
    },
    // 删除树节点
    removeTree(node) {
      this.$confirm('永久删除该科室节点, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const param = {
            id: node.data.id,
          };
          this.$api.deptDelete(param).then((res) => {
            if (res.code === 0) {
              this.$message({
                type: 'success',
                message: '删除科室成功!',
              });
              this.getDepartmentList();
            }
          });
        })
        .catch(() => {});
    },
    // 删除Key和相应的
    delData(i) {
      this.fom.auth.splice(i, 1);
    },
    // 菜单树点击
    handleNodeClick(data) {
      console.log(data);
    },
    // 查询科室树
    getDepartmentList() {
      this.deptTreeDeptCodeList = [];
      this.deptQueryList({}).then((res) => {
        res.data.forEach((item) => {
          this.deptTreeIdList.push(item.id);
        });
        this.listToTree(res.data);
      });
    },
    // 菜单列表转树
    listToTree(oldArr) {
      oldArr.forEach((element) => {
        const parentDeptCode = element.parentDeptCode;
        if (parentDeptCode !== null) {
          oldArr.forEach((ele) => {
            if (ele.deptCode === parentDeptCode) {
              // 当内层循环的ID== 外层循环的parendId时，（说明有children），需要往该内层id里建个children并push对应的数组；
              if (!ele.children) {
                ele.children = [];
              }
              ele.children.push(element);
            }
          });
        }
      });

      oldArr = oldArr.filter(
        (ele) => !ele.parentDeptCode || ele.parentDeptCode === null
      ); // 这一步是过滤，按树展开，将多余的数组剔除；
      console.log(oldArr, 'oldarr');
      this.treeList = oldArr;
    },
    // 保存科室信息
    saveData() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const param = {
            ...this.form,
            parentDeptCode:
              this.titleType === '编辑科室'
                ? this.deptInfo.parentDeptCode
                : this.deptInfo.deptCode,
            parentDeptName:
              this.titleType === '编辑科室'
                ? this.deptInfo.parentDeptName
                : this.deptInfo.deptName,
          };
          if (this.titleType === '编辑科室') {
            param.id = this.deptInfo.id;
          }
          this.deptEdit(param).then((res) => {
            if (res.code === 0) {
              this.$message.success(
                this.titleType === '编辑科室' ? '编辑科室成功' : '新增科室成功'
              );
              this.isDetail = true;
              this.getDepartmentList();
            }
          });
        }
      });
    },
    resetData() {
      const { deptInfo } = this;
      this.form = {
        deptCode: deptInfo.deptCode,
        deptName: deptInfo.deptName,
        logo: deptInfo.logo,
      };
      this.isDetail = true;
    },
    // 查看科室详情
    queryDetail(node, data) {
      this.dialogVisible = true;
      this.titleType = '科室详情';
      this.isDetail = true;
      this.form = {
        deptCode: data.deptCode,
        deptName: data.deptName,
        logo: data.logo,
      };
    },
    queryOrgDetail() {
      const param = {
        id: this.currentOrgId,
      };
      this.getOrg(param).then((res) => {
        this.form = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;
  justify-content: space-between;
}

.iconColor {
  color: rgb(22, 155, 223);
}

.custom-tree-node {
  display: flex;
  padding-right: 8px;
  font-size: 14px;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  .labelName {
    width: 200px;
  }
}

.flex {
  display: flex;
  // height: 200px;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
}

.pageBtn {
  width: 60%;
  height: 200px;
  background-color: #fff;
  border: 1px solid #000;
}

.key {
  display: flex;
  margin-left: 5px;
}

.orgInfo {
  padding: 10px;
  margin-left: 20px;
  // border: 1px solid #fff;
}
</style>
